import * as React from 'react';

// import Layout from "../components/layout";
// import Seo from "../components/seo";

const NotFoundPage = () => (
  // <Layout>
  //   <Seo title="Error" />
  <div className="hero has-text-centered py-6">
    <h1 className="title is-1">Error</h1>
  </div>
  // </Layout>
);

export default NotFoundPage;
